// import package
import React, { useState } from "react";
import { MenuItem, Select, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// import component
import WalletTransfer from "./WalletTransfer";
import CryptoDeposit from "./CryptoDeposit";
import CryptoWithdraw from "./CryptoWithdraw";
import FundTransfer from "./FundTransfer";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import { toFixed } from "../../lib/roundOf";
import { convertSymbolLogo } from "../../lib/statusCode";

const CryptoWallet = () => {
  const { t, i18n } = useTranslation();

  // state
  const [walletType, setWalletType] = useState("p2p");
  const [model, setModal] = useState({
    type: "",
    assetData: {},
    currency: {},
  });

  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const currencyDoc = useSelector((state) => state.currency);
  const accountDoc = useSelector((state) => state.account);
  const userSetting = useSelector((state) => state.userSetting);
  const priceConversion = useSelector((state) => state.priceConversion);
  const userKycData = useSelector((state) => state.userKyc);

  const { idProof, addressProof } = userKycData;
  console.log("WalletData", walletData);
  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setWalletType(value);
  };

  const modalClose = () => {
    setModal({
      type: "",
      assetData: {},
      currency: {},
    });
  };

  const tokenType = (currency) => {
    if (isEmpty(currency)) {
      return "";
    }

    if (currency.type == "token") {
      if (currency.tokenType == "prc20") {
        return "PAV20";
      }

      return `${currency.tokenType.toUpperCase()}`;
    }

    return "";
  };
  console.log("accountDoc", accountDoc);
  // console.log(accountDoc,"accountDocaccountDocaccountDoc")
  return (
    <div className="p2p_card min-h-auto input_box-flex">
      <WalletTransfer
        show={model.type == "walletTransfer"}
        assetData={model.assetData}
        onHide={modalClose}
      />
      <FundTransfer
        show={model.type == "fundTransfer"}
        assetData={model.assetData}
        currency={model.currency}
        onHide={modalClose}
      />
      <CryptoDeposit
        show={model.type == "deposit"}
        assetData={model.assetData}
        onHide={modalClose}
      />
      <CryptoWithdraw
        show={model.type == "withdraw"}
        assetData={model.assetData}
        currency={model.currency}
        onHide={modalClose}
      />
      {/* <div className="d-flex justify-content-between align-items-center mb-3 pb-3">
                <h3 className="login_title_8 mb-0 pb-0">{t("CRYPTO_WALLET")}</h3>
                    <div className="seacr_box_s">
                        <Select
                            name="walletType"
                            value={walletType}
                            label="Locked"
                            onChange={handleChange}
                        > */}
      {/* <MenuItem value={'spot'}>{t("SPOT")}</MenuItem>                         */}
      {/* <MenuItem value={'p2p'}>{t("P2P")}</MenuItem> */}
      {/* <MenuItem value={'derivative'}>{t("DERIVATIVE")}</MenuItem> */}
      {/* </Select> */}
      {/* </div> */}
      {/* <div className="seacr_box_s">
                    <input type="text" placeholder={t("FIND_COIN")} />
                    <i class="fas fa-search"></i>
                </div> */}
      {/* </div> */}
      <div className="fialt_wallet_sectoin  table-responsive">
        {currencyDoc &&
          currencyDoc.length > 0 &&
          walletData &&
          walletData.length > 0 &&
          walletData.map((item, key) => {
            let curData = currencyDoc.find((el) => el.coin == item.coin);
            let priceCNV = priceConversion.find(
              (el) =>
                el.baseSymbol == item.coin &&
                el.convertSymbol == userSetting.currencySymbol
            );
            let convertCurData = currencyDoc.find(
              (el) => el.coin == userSetting.currencySymbol
            );
            console.log(curData, "curDatacurData");
            if (
              curData &&
              ["crypto", "token"].includes(curData.type) &&
              !["LINK", "BUSD", "SHIB", "LTC", "DOGE", "XRP", "ETH"].includes(
                curData.coin
              )
            ) {
              console.log("Item", item);
              return (
                <div className="fiat_wallet_list" key={key}>
                  <div>
                    <div className="fiat_wallet_cur">
                      <img
                        src={curData && curData.image}
                        alt="logo"
                        className="img-fluid"
                      />
                      <span>{item.coin}</span>
                    </div>
                    {/* { walletType == 'spot' &&  <p className="wallet_spot_bal">{ toFixed(item.spotBal,curData.decimal)}</p> }
                                        { walletType == 'derivative' && <p className="wallet_spot_bal">{toFixed(item.derivativeBal,curData.decimal)}</p> } */}
                    {walletType == "p2p" && (
                      <p className="wallet_spot_bal">
                        {toFixed(item.p2pBal, curData.decimal)}
                      </p>
                    )}

                    <p className="wallet_token_type">{tokenType(curData)}</p>
                    <p className="wallet_conerted_bal">
                      {convertSymbolLogo(
                        userSetting && userSetting.currencySymbol
                      )}
                      {curData.coin == "USDT" &&
                      userSetting.currencySymbol == "USD"
                        ? toFixed(item.p2pBal, curData.decimal)
                        : priceCNV &&
                          priceCNV.convertPrice &&
                          toFixed(
                            parseFloat(priceCNV.convertPrice) *
                              parseFloat(item.p2pBal),
                            convertCurData ? convertCurData.decimal : 2
                          )}
                    </p>
                  </div>
                  {/* { curData && curData.status == 'active' &&     */}
                  <div className="button_lst_section">
                    {
                      /* walletType == 'spot' */ walletType == "p2p" && (
                        <div
                          className={`Subscribe ${
                            ((curData && curData.depositStatus == "off") ||
                              curData.status !== "active") &&
                            "Subscribe_disabled"
                          }`}
                        >
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              setModal({
                                type: "deposit",
                                assetData: item,
                                currency: curData,
                              });
                            }}
                            disabled={
                              (curData && curData.depositStatus == "off") ||
                              curData.status != "active"
                            }
                          >
                            {t("DEPOSIT")}
                          </Button>
                        </div>
                      )
                    }

                    {
                      /* walletType == 'spot' */ walletType == "p2p" && (
                        <div
                          className={`Subscribe ${
                            ((curData && curData.withdrawStatus == "off") ||
                              curData.status != "active" ||
                              isEmpty(accountDoc.withdrawRestrict) ||
                              !accountDoc.withdrawRestrict.includes(
                                curData._id
                              )) &&
                            "Subscribe_disable"
                          }`}
                        >
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              if (
                                idProof.status != "approved" ||
                                addressProof.status != "approved"
                              ) {
                                toastAlert(
                                  "error",
                                  "Please submit your kyc",
                                  "FIAT_WITHDRAW"
                                );
                              } else if (accountDoc.emailStatus != "verified") {
                                toastAlert(
                                  "error",
                                  "Please update your email",
                                  "FIAT_WITHDRAW"
                                );
                              } else if (
                                accountDoc &&
                                accountDoc.phoneStatus != "verified"
                              ) {
                                toastAlert(
                                  "error",
                                  "Please update your mobile number",
                                  "FIAT_WITHDRAW"
                                );
                              } else {
                                setModal({
                                  type: "withdraw",
                                  assetData: item,
                                  currency: curData,
                                });
                              }
                            }}
                            disabled={
                              (curData && curData.withdrawStatus == "off") ||
                              curData.status != "active" ||
                              (!isEmpty(accountDoc.withdrawRestrict) &&
                                !accountDoc.withdrawRestrict.includes(
                                  curData._id
                                ))
                            }
                          >
                            {t("WITHDRAW")}
                          </Button>
                        </div>
                      )
                    }

                    {/* <div className="Subscribe">
                                            <Button className="btn-primary" onClick={() => {
                                                setModal({
                                                    type: 'walletTransfer',
                                                    assetData: item,
                                                    currency: curData
                                                })
                                            }}>{t("TRANSFER")}</Button>
                                        </div> */}

                    {/* <div className="Subscribe">
                                            <Button className="btn-primary" onClick={() => {
                                                setModal({
                                                    type: 'fundTransfer',
                                                    assetData: item,
                                                    currency: curData
                                                })
                                            }}>{t("FUND")}</Button>
                                        </div> */}
                    {/* <div className="Subscribe"><Button className="btn-primary" data-toggle="modal" data-target="buyModalCenter">Stake</Button></div> */}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default CryptoWallet;
