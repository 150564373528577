// import package
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Select, MenuItem } from '@material-ui/core';

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { autoWithdrawRequestCoin, withdrawRequestCoin } from '../../actions/walletAction';

// import lib
import isEmpty from '../../lib/isEmpty';
import { coinValidation } from './validation'
import { toastAlert } from "../../lib/toastAlert";
import { precentConvetPrice } from '../../lib/calculation';
import { encryptObject } from '../../lib/cryptoJS'

const initialFormValue = {
    'currencyId': '',
    'amount': '',
    'receiverAddress': '',
    'destTag': '',
    'password': '',
    'twoFACode': '',
    'finalAmount': '',
    'otpTextBox': false,
    'otp': ''
}

const CryptoWithdraw = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    
    // props
    const { show, assetData, currency, onHide } = props;
 

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState(false)

    const {
        currencyId,
        amount,
        receiverAddress,
        destTag,
        password,
        twoFACode,
        finalAmount,
        otpTextBox,
        otp
    } = formValue;

    // function
    const handleClose = () => {
        setFormValue(initialFormValue)
        setValidateError({})
        onHide()
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (!isEmpty(validateError)) {
            setValidateError({})
        }

        if (name == 'amount') {
            if (!(value == '' || (!isNaN(value))/*(/^[0-9\b]+$/.test(value))*/)) {
                return 
            }
            // let finalAmountBal = parseFloat(value) + precentConvetPrice(value, currency.withdrawFee); // percentage withdraw
            let finalAmountBal = parseFloat(value) + parseFloat(currency.withdrawFee); // amount withdraw
            let formData = { ...formValue, ...{ [name]: value, 'finalAmount': finalAmountBal } }
            setFormValue(formData)
            return
        }

        if (name == 'twoFACode') {
            if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
                return
            }
        }

        if (name == 'otp') {
            if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
                return
            }
        }

        if (name == 'destTag') {
            if (!(value == '' || (/^[0-9\b]+$/.test(value)))) {
                return
            }
        }

        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }

    const handleSubmit = async () => {
        setLoader(true)
        let reqData = {
            currencyId: currency._id,
            coin: currency.coin,
            tokenType: currency.tokenType,
            minimumWithdraw: currency.minimumWithdraw,
            amount,
            receiverAddress,
            destTag,
            twoFACode,
            finalAmount,
            p2pBal: assetData.p2pBal,
            otpTextBox,
            otp
        }

        
        let validationError = coinValidation(reqData, t, (assetData && assetData.coin))
        if (!isEmpty(validationError)) {
            setValidateError(validationError)
            setLoader(false)
            return
        }

        let encryptToken = {
            token: encryptObject(reqData)
        }

        try {
            // const { status, loading, error, message } = await withdrawRequestCoin(encryptToken,dispatch) // ADMIN APPROVE WITHDRAW
            const { status, loading, error, message } = await withdrawRequestCoin(encryptToken,dispatch) // USER AUTO WITHDRAW
            setLoader(loading)
            if (status == 'success') {
                setFormValue(initialFormValue)
                handleClose()
                toastAlert('success', t(message), 'withdraw');
            } else if(status == 'OTP'){
                setFormValue({...formValue,...{'otpTextBox' : true } })
                toastAlert('success', t(message), 'withdraw');
                return
            } else {
                if (error) {
                    setValidateError(error)
                    return
                }
                setFormValue(initialFormValue)
                handleClose()
                toastAlert('error', t(message), 'withdraw');

            }
        }
        catch (err) { }
    }

    const handleAutoSubmit = async () => {
        setLoader(true)
        let reqData = {
            currencyId: currency._id,
            coin: currency.coin,
            tokenType: currency.tokenType,
            minimumWithdraw: currency.minimumWithdraw,
            amount,
            receiverAddress,
            destTag,
            twoFACode,
            finalAmount,
            p2pBal: assetData.p2pBal,
            otpTextBox,
            otp
        }


        let validationError = coinValidation(reqData, t, (assetData && assetData.coin))
        if (!isEmpty(validationError)) {
            setValidateError(validationError)
            setLoader(false)
            return
        }

        let encryptToken = {
            token: encryptObject(reqData)
        }

        try {
            // const { status, loading, error, message } = await withdrawRequestCoin(encryptToken,dispatch) // ADMIN APPROVE WITHDRAW
            const { status, loading, error, message } = await autoWithdrawRequestCoin(encryptToken,dispatch) // USER AUTO WITHDRAW
            setLoader(loading)
            if (status == 'success') {
                setFormValue(initialFormValue)
                handleClose()
                toastAlert('success', t(message), 'withdraw');
            } else if(status == 'OTP'){
                setFormValue({...formValue,...{'otpTextBox' : true } })
                toastAlert('success', t(message), 'withdraw');
                return
            } else {
                if (error) {
                    setValidateError(error)
                    return
                }
                setFormValue(initialFormValue)
                handleClose()
                toastAlert('error', t(message), 'withdraw');
            }
        }
        catch (err) { }
    }
    
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('CRYPTO_WITHDRAW')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={5}>
                        <label>{t('AMOUNT')}</label>
                        <div className="form-group  ">
                            <div class="seacr_box_s">
                                <input type="text" placeholder=""
                                    name='amount'
                                    value={amount}
                                    onChange={handleChange}
                                    disabled={otpTextBox}
                                />
                                <i class="">{assetData && assetData.coin}</i>
                            </div>
                        </div>
                        {validateError.amount && <p className="error-message">{t(validateError.amount).replace('##MINIMUM_WITHDRAW##',(currency && currency.minimumWithdraw))}</p>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={7}>
                        <label>{t("WITHDRAW_ADDRESS")}</label>
                        <div class="form-group">
                            <div class="seacr_box_s">
                                <input type="text"
                                    name='receiverAddress'
                                    value={receiverAddress}
                                    onChange={handleChange}
                                    disabled={otpTextBox}
                                />
                            </div>
                            {validateError.receiverAddress && <p className="error-message">{t(validateError.receiverAddress)}</p>}
                        </div>
                    </GridItem>
                { assetData.coin == "XRP" &&
                    <GridItem xs={12} sm={12} md={6} lg={7}>
                        <label>{t("DEST_TAG")}</label>
                        <div class="form-group">
                            <div class="seacr_box_s">
                                <input type="text"
                                    name='destTag'
                                    value={destTag}
                                    onChange={handleChange}
                                    disabled={otpTextBox}
                                />
                            </div>
                            {validateError.destTag && <p className="error-message">{t(validateError.destTag)}</p>}
                        </div>
                    </GridItem>
                }    
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="wallwt_balance">
                            <p>{t('WALLET_BALANCE')}<span>{assetData && assetData.p2pBal} {assetData.coin}</span></p>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                        <label>{t('FINAL_WITHDRAW_AMOUNT')}</label>
                        <div className="form-group  ">
                            <div class="seacr_box_s">
                                <input type="text" placeholder="" value={finalAmount} disabled />
                                <i class="">{assetData.coin}</i>
                            </div>
                             {validateError.finalAmount && <p className="error-message">{t(validateError.finalAmount)}</p>}
                        </div>
                    </GridItem>

                { otpTextBox &&    
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                        <label>{t('ENTER_OTP_CODE')}</label>
                        <div className="form-group  ">
                            <div class="seacr_box_s">
                                <input type="text"
                                    placeholder=""
                                    name='otp'
                                    value={otp}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {validateError.otp && <p className="error-message">{t(validateError.otp)}</p>}
                    </GridItem>
                } 
                    {/*<GridItem xs={12} sm={12} md={12} lg={6}>
                        <label>{t('ENTER2FA_CODE')}</label>
                        <div className="form-group  ">
                            <div class="seacr_box_s">
                                <input type="text"
                                    placeholder=""
                                    name='twoFACode'
                                    value={twoFACode}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {validateError.twoFACode && <p className="error-message">{t(validateError.twoFACode)}</p>}
                    </GridItem>*/}
                    <GridItem md={12}>

                    {/* {['RELANAS','PAVO'].includes(assetData.coin) && 
                    <div className="submit_btn w-100">
                        <Button className="w-100" onClick={handleAutoSubmit} disabled={loader}>
                            {loader && <i className="fas fa-spinner fa-spin"></i>}
                            {t("WITHDRAW")}
                        </Button>
                    </div>
                    } */}

                    
                    <div className="submit_btn w-100">
                        <Button className="w-100" onClick={handleSubmit} disabled={loader}>
                            {loader && <i className="fas fa-spinner fa-spin"></i>}
                            {t("WITHDRAW")}
                        </Button>
                    </div>
                    

                    </GridItem>
                    <GridItem md={12}>
                    <div className="notes_section">
                        <p>{t('NOTES')}</p>
                        <ul>
                            <li>1. {t('MIN_WITHDRAW_LIMIT').replace('##CURRENCY##',currency.coin).replace('##MIN_WITHDRAW##',currency.minimumWithdraw)}</li>
                            <li>2. {t('MAX_WITHDRAW_LIMIT').replace('##CURRENCY##',currency.coin).replace('##MAX_WITHDRAW##',currency.maximumWithdraw)}</li>
                            <li>3. {t('WITHDRAW_FEE').replace('##WITHDRAW_FEE##',currency.withdrawFee).replace('##CURRENCY##',currency.coin)}</li>
                          {
                            !['RELANAS', 'PAVO', 'GECC', 'UPRO', 'TRX'].includes(currency.coin) && 
                            <li>4. {t('WITHDRAW_COIN_TIME_TAKE')}</li>
                          }
                        </ul>
                    </div>
                    </GridItem>
                </GridContainer>
            </Modal.Body>
        </Modal>
    )
}

export default CryptoWithdraw;