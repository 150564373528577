// import package
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { setQRForm } from "../../actions/users";
import config from "../../config";

const ListQRDetail = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
    const { formDisable, formType, editRecord } = useSelector(
      (state) => state.qrCodeDetail
    );

  // redux-state
  const { result } = useSelector((state) => state.qrCodeDetail);

  return (
    <>
      {result && result.length == 0 && (
        <>
          <div className="boxSpace">
            {/*<p>{t('REGISTER_QR_ACCOUNT')}</p>*/}
            <div className="form-group">
              <button
                hidden={!formDisable}
                onClick={() => {
                  dispatch(
                    setQRForm({
                      formDisable: false,
                      formType: "add",
                    })
                  );
                }}
                className="btn btn-primary text-uppercase py-2"
              >
                {t("ADD_QR_ACCOUNT")}
              </button>
            </div>
          </div>
        </>
      )}

      {result && result.length > 0 && (
        <div className="profileDetailView">
          <GridContainer>
            {result.map((item, key) => {
              if (item.frontImage != "undefined" || item.frontImage == "")
                var imagepath =
                  config.API_URL + "/images/qr/" + item.frontImage;
              return (
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <h4>
                    {t("QR_ACCOUNT")} #{key + 1} : {item._id}
                  </h4>
                  <ul>
                    <li>
                      {/* <label>{t("QR_NAME")}</label> */}
                      <span>
                        <img src={imagepath} height="200" width="200" />
                      </span>
                    </li>
                    {/* <li>
                                                <label>{t("ACCOUNT_NUMBER")}</label>
                                                <span>{item.accountNo}</span>
                                            </li>
                                            <li>
                                                <label>{t("ACCOUNT_HOLDER_NAME")}</label>
                                                <span>{item.holderName}</span>
                                            </li>
                                            <li>
                                                <label>{t("IBAN_CODE")}</label>
                                                <span>{item.bankcode}</span>
                                            </li>
                                            <li>
                                                <label>{t("BANK")}{" "}{t("CITY")}</label>
                                                <span>{item.city}</span>
                                            </li>
                                            <li>
                                                <label>{t("BANK")}{" "}{t("COUNTRY")}</label>
                                                <span>{item.country}</span>
                                            </li> */}
                  </ul>
                </GridItem>
              );
            })}
          </GridContainer>
          <div className="form-group mb-0">
            <button
              className="btn btn-outline py-2 text-uppercase"
              onClick={() => {
                dispatch(
                  setQRForm({
                    formDisable: false,
                    formType: "add",
                  })
                );
              }}
            >
              Add Another Account
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ListQRDetail;
