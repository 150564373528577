// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  var result = /^[\w.-]+@[\w.-]+$/;
  var match = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{3,}/;
  let regex = new RegExp(/^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/);

  let regularEx = /[\.\-a-z0-9]+@[a-z]+/gm;
  if (isEmpty(value.upiId)) {
    errors.upiId = "UPI Id Required";
  }
  if (!result.test(value.upiId)) {
    errors.upiId = "Please enter a valid Upi Id";
  }
  // if(isEmpty(value.accountNo)){
  //     errors.accountNo = "REQUIRED"
  // }

  // if(isEmpty(value.holderName)){
  //     errors.holderName = "REQUIRED"
  // }

  // if(isEmpty(value.bankcode)){
  //     errors.bankcode = "REQUIRED"
  // }

  // if(isEmpty(value.country)){
  //     errors.country = "REQUIRED"
  // }

  // if(isEmpty(value.city)){
  //     errors.city = "REQUIRED"
  // }

  return errors;
};

export default validation;
