import React, { createContext, useState } from "react";

export const ProfileShowContext = createContext();

export const ProfileProvider = (props) => {
  const [showProfile, setShowProfile] = useState(false);
  const [hideProfile, setHideProfile] = useState(false);
  const [editHide, setEditHide] = useState(false);
  return (
    <ProfileShowContext.Provider
      value={[
        showProfile,
        setShowProfile,
        hideProfile,
        setHideProfile,
        editHide,
        setEditHide,
      ]}
    >
      {props.children}
    </ProfileShowContext.Provider>
  );
};
