import React from "react";
import ReactDOM from "react-dom";
import './index.css';

import App from './App';
import { ProfileProvider } from "context/ProfileShown";


ReactDOM.render(
  <ProfileProvider>
    <App />
  </ProfileProvider>,
  document.getElementById("root")
);
